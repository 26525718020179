@import '_global';

@mixin animation {
	transition: all 0.3s ease-in-out 0s;
}

@mixin brandButton {
	button, a {
		border-radius: 30px;
		padding: 5px 30px;
		font-size: 0.95rem;
		margin-right: 20px;
		word-spacing: 4px;
		letter-spacing: 1px;
		color: white;
	}
}

@mixin downloadForm {
	form {
		> div {
			width: 250px;
			display: inline-block;

			input[type=email] {
				width: 250px;
				padding: 22px;
				border-radius: 5px;
				border: 1px solid $dark-grey;
			}
		}
	}

	button {
		width: 250px;
		padding: 3px;
		border-radius: 5px;
		letter-spacing: 2px;
	}
}