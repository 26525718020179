@import '_mixin';

.react-autosuggest__container {
    border: solid 1px $grey;
    border-radius: 20px;
    background: $lightest-grey;
    font-size: 1rem;
    height: 40px;
    width: 200px;
}

.react-autosuggest__container--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.react-autosuggest__input {
    color: $darker-grey;
    border: none;
    background: $lightest-grey;
    margin-top: 12px;
    margin-left: 15px;
    font-family: 'Roboto', sans-serif;
}

.react-autosuggest__input:focus {
    outline: none;
    background: $lightest-grey;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 200px;
    margin-left: -1px;
    margin-top: 2px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #eee;
}

.suggestion-title {
    font-weight: bold;
}

.suggestion-sentence {
    font-size: smaller;
}
