@import '_mixin';

.uk-navbar-container:not(.uk-navbar-transparent) {
	background: $white;
	border-bottom: 1px solid $light-grey;
	box-shadow: 0 10px 30px rgba(0,0,0,0.075);

	.uk-navbar-left {
		.uk-navbar-nav {
			> li {
				> a {
					font-family: 'Prompt', sans-serif;
					font-size: 1.5rem;
					min-height: 70px;

					&:link, &:visited {
						color: $primary;
					}
				}
			}
		}
	}

	.uk-navbar-right {
		.uk-navbar-nav {
			> li {
				> a {
					min-height: 70px;

					&:link, &:visited {
						color: $darker-grey;
						padding: 0 25px;
						font-size: 0.925rem;

						@include animation;
					}

					&:hover, &:active, &:focus {
						color: $primary;
					}

					&.selected {
						&:link, &:visited {
							color: $primary;
						}
					}
				}
			}
		}

		.uk-navbar-item {
			min-height: 70px;
		}
	}
}