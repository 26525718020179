@import '_mixin';

.home {
	overflow-y: scroll;
	scroll-behavior: smooth;
}

article {
	font-family: 'Roboto', sans-serif;
	h2 {
		font-family: 'Noto Sans JP', sans-serif;
		color: $darkest-grey;

		> span {
			color: $primary;
		}
	}

	&.p-header {
		background-size: cover;

		h1 {
			font-family: 'Noto Sans JP', sans-serif;
			color: $primary-dark;

			> span {
				color: $primary;
			}
		}

		h3 {
			font-family: 'Noto Sans JP', sans-serif;
			color: $darker-grey;
		}

		h4 {
			font-family: 'Roboto', sans-serif;
			color: $darker-grey;
			line-height: 2rem;
		}

		.uk-slideshow-items {
			& ~ a {
				&:link, &:visited {
					border: 1px solid $light-grey;
					border-radius: 50%;
					background: $light-grey;
					color: $lightest-grey;
				}
			}
		}

		@include brandButton;

		.uk-button-default {
			color: $darker-grey;
			background: $white;
		}
	}

	&.p-features {
		h3 {
			color: $primary;
		}

		p {
			line-height: 2rem;
		}

		h5 {
			> button {
				> span {
					color: $primary;
					@include animation;
					
					&:nth-child(2) {
						border-radius: 50%;
						border: 0.12rem solid $primary;
						width: 35px;
						height: 35px;
						align-items: center;
						justify-content: center;
						display: inline-flex;
						@include animation;
					}
				}

				&:hover {
					text-decoration: none;

					> span {
						color: $primary-dark;

						&:nth-child(2) {
							transform: scale(1.1);
							border-color: $primary-dark;
						}
					}
				}
			}
		}

		figure {		
			> div {
				cursor: pointer;
				color: $grey;

				> span {
					&:nth-child(1) {
						display: none;
						background: rgba(255,255,255,0.15);
						padding: 5px;
						font-size: 0.75rem;
					}
				}
			}

			&:hover {
				> div {
					> span {
						&:nth-child(1) {
							display: inline;
						}
					}
				}
			}
		}
	}

	&.p-footer {
		@include downloadForm;

		h3 {
			font-family: 'Noto Sans JP', sans-serif;
			color: $darker-grey;
		}

		figure {
			> a {
				color: $darker-grey;

				> span {
					cursor: pointer;
					@include animation;

					&:hover {
						background: $white;
					}
				}
			}
		}
	}

	&.p-download {
		@include downloadForm;
	}
}