@import '_mixin';

.topics {

}

h2 {
  font-family: 'Noto Sans JP', sans-serif;
  &:nth-of-type(1) {
    color: $primary;
  }
}

h3 {
  font-family: 'Noto Sans JP', sans-serif;
}

pre {
  background: $primary-editorBg;
  color: $primary-editor;
}

.uk-table {
  thead {
    tr {
      th {
        border-bottom: 2px solid $dark-grey;
        color: $darker-grey;
        font-weight: 600;
      }
    }
  }
}

.topics {
    margin-top: 20px;

    >.sidebar {
        position: fixed;
        width: 200px;
        height: calc(100% - 50px);
        padding-left: 20px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 15px;
        overflow-x: hidden;
        overflow-y: scroll;
        font-family: 'Roboto', sans-serif;
    }

  .topic {
    border-left: 1px solid $lighter-grey;
  }
}

.topics > .content {
  position: fixed;
  left: 220px;
  width: calc(100% - 250px);
  height: calc(100% - 100px);
  padding: 0 20px;
  overflow-x: auto;
  overflow-y: scroll;
}

.topic > .active {
  font-weight: bolder;
}

.topic {
  > li {
    > .p-button-customPrimary {
      padding: 5px;
      padding-left: 20px;
      font-size: 1rem;
      text-transform: none;
      background: transparent;

      &:hover {
        color: $primary-dark;
      }
    }
  } 

  &.level-1, &.level-2 {
    > li {
      &.uk-active {
        border-left: 1px solid $primary;
        color: $primary;
      }
    }
  }

  &.level-3 {
    > li {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 1.25rem;
        background: $darker-grey;
        border-radius: 50%;
        width: 7px;
        height: 7px;
        display: inline-block;
      }

      &.uk-active {
        color: $primary;

        &:before {
          background: $primary;
        }
      }
    }
  }
}

.topic.level-2 {
  padding-left: 14px;
}

.topic.level-2 > li {
  display: list-item;
  list-style: none;
}

.topic.level-3 {
  padding-left: 20px;
}

.topic.level-3 > li {
  display: list-item;
  list-style: none;
}

.keywords {
  column-count: 6;
}

pre {
  max-width: 1000px;
}
